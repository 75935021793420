@import url(https://fonts.googleapis.com/css?family=Lobster);

/* Forms */

.form-group {
  margin-bottom: 1rem;
}
.input-group-append,
.input-group-prepend {
  color: $input-placeholder-color;
  width: auto;
  border: none;

  .input-group-text {
    border-color: $border-color;
    padding: $input-padding-y 0.75rem;
    color: $input-placeholder-color;
    background: none;
  }
}
.form-control {
  border: 1px solid $border-color;
  height: calc(2.25rem + 2px);
  font-weight: normal;
  font-size: $input-font-size;
  padding: 0.625rem 0.6875rem;
  background-color: $input-bg;
  border-radius: 2px;
  color: #ffffff;
  &:focus {
    border: 1px solid rgba(168, 40, 74);
    background-color: $input-bg;
  }
  &.form-control-lg {
    padding: 0.94rem 0.75rem;
    height: calc(2.875rem + 2px);
  }

  &.form-control-sm {
    padding: 0.5rem 0.75rem;
    height: calc(1.8125rem + 2px);
  }
  &::placeholder {
    color: $text-muted;
    font-size: $input-placeholder-font-size;
  }
}
.space-between {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.two-inputs__flex {
  display: flex;
  flex-wrap: wrap;
  width: max-content;
}
.navbar {
  .form-control {
    width: 75%;
    border-radius: 6px;
    &:focus {
      border: none;
    }
  }
}

select {
  &.form-control {
    padding: 0.4375rem 0.75rem;
    border: 0;
    outline: 1px solid #322f2f;
    color: $input-placeholder-color;
    &:focus {
      outline: 1px solid $border-color;
    }
    @each $color, $value in $theme-colors {
      &.border-#{$color} {
        outline: 1px solid $value;
        &:focus {
          outline: 1px solid $value;
        }
      }
    }
  }
}
.form-group {
  label {
    font-size: $default-font-size;
    line-height: 1;
    vertical-align: top;
  }
  &.has-danger {
    .form-control {
      border-color: theme-color(danger);
    }
  }
  .file-upload-default {
    visibility: hidden;
    position: absolute;
  }
  .file-upload-info {
    background: transparent;
  }
}

.form-check {
  min-height: 18px;
  padding-left: 1.25rem;
}

div {
  &.tagsinput {
    background: transparent;
  }
}

.custom-file {
  .visibility-hidden {
    visibility: hidden;
  }
  .custom-file-label {
    background: $input-bg;
    border: 1px solid $border-color;
    height: calc(2.25rem + 2px);
    font-weight: normal;
    font-size: 0.875rem;
    padding: 0.625rem 0.6875rem;
    border-radius: 2px;
    &:after {
      background-color: #e91e63;
      height: auto;
      @extend .btn-primary;
    }
  }
}

@import url("https://fonts.googleapis.com/css?family=Raleway:400,700");

.container-login {
  display: flex;
  align-items: center;
  justify-content: center;
  // min-height: 100vh;
}

.screen {
  background-image: linear-gradient(rgba(168, 40, 74), rgb(218, 120, 75));
  position: relative;
  height: 600px;
  width: 360px;
  box-shadow: 0px 0px 28px #1f2833;
}
@media screen and (min-width: 700px) {
  .screen {
    width: 50vw;
    height: 70vh;
  }

  .form-control input {
    font-size: 16px !important;
  }
}
.screen__content {
  z-index: 1;
  position: relative;
  height: 100%;
}

.screen__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  -webkit-clip-path: inset(0 0 0 0);
  clip-path: inset(0 0 0 0);
}

.screen__background__shape {
  transform: rotate(45deg);
  position: absolute;
}

.screen__background__shape1 {
  height: 520px;
  width: 520px;
  background: #000;
  top: -50px;
  right: 120px;
  border-radius: 0 72px 0 0;
}

.screen__background__shape2 {
  height: 230px;
  width: 220px;
  background: #000000;
  top: -192px;
  right: -14px;
}

.screen__background__shape3 {
  height: 960px;
  width: 190px;
  background: #000000;
  top: -230px;
  right: 0;
  border-radius: 32px;
}

.screen__background__shape4 {
  height: 400px;
  width: 200px;
  background: #000;
  top: 420px;
  right: 50px;
  border-radius: 20px;
}

// .login {
//   width: 320px;
//   padding: 30px;
//   padding-top: 156px;
// }

.login__field {
  padding: 20px 0px;
  position: relative;
}

.login__icon {
  position: absolute;
  top: 30px;
  background-image: linear-gradient(rgba(168, 40, 74), rgb(218, 120, 75));
}

.login__input {
  border: none;
  background: #000000;
  padding: 10px;
  padding-left: 24px;
  font-weight: 700;
  width: 85%;
  transition: 0.2s;
  color: #fafafa;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(to right, rgba(168, 40, 74), rgb(218, 120, 75))
    1;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
  outline: none;
  border-bottom-color: #fafafa; /* Set the border-bottom-image property */
}

.login__submit {
  position: relative;
  background: #000;
  border-radius: 16px;
  box-shadow: 0px 2px 2px #000;
  color: #fafafa;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-top: 50px;
  // overflow: hidden;
  padding: 10px 20px;
  text-transform: uppercase;
  transition: 0.2s;
  width: 100%;
}

.login__submit::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(168, 40, 74), rgb(218, 120, 75));
  border-radius: 16px;
  z-index: -1;
}

.login {
  position: absolute;
  top: 50%;
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  user-select: none;
  left: 43%;
  margin: -10rem 0 0 -10rem;
  width: 30rem;
  height: 20rem;
  padding: 3em;
  background: hsla(255, 255%, 255%, 1);
  border-radius: 15px;
  // overflow: hidden;
  transition: all 1s ease;
}
.login:hover > .header,
.login.clicked > .header {
  width: 2rem;
}
.login:hover > .header > .text,
.login.clicked > .header > .text {
  font-size: 1rem;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.login.loading {
  width: 20rem !important;
  border-radius: 50% !important;
}
.login.loading > .header {
  width: 20rem;
  transition: all 1s ease;
  border-radius: 50%;
}
.login.loading > .header > .text {
  display: none;
}
.login.loading > .header > .loader {
  display: block;
}
.header {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 30rem;
  height: 20rem;
  background: hsla(0, 5%, 0%, 1);
  transition: width 0.5s ease-in-out;
}
.header > .text {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 5rem;
  text-align: center;
  line-height: 20rem;
  color: hsla(255, 255%, 255%, 1);
  transition: all 0.5s ease-in-out;
}
.header > .loader {
  display: none;
  position: absolute;
  left: 5rem;
  top: 5rem;
  width: 10rem;
  height: 10rem;
  border-left: 10px solid hsla(255, 255%, 255%, 1);
  border-bottom: 10px solid hsla(255, 255%, 255%, 1);
  border-right: 10px solid hsla(255, 255%, 255%, 1);
  border-top: 8px solid hsla(255, 255%, 255%, 1);
  border-radius: 50%;
  box-shadow: inset 2px 2px 2px 2px hsla(0, 5%, 0%, 1);
  animation: loading 2s linear infinite;
}
.header > .loader:after {
  content: "";
  position: absolute;
  left: 4.15rem;
  top: -0.5rem;
  width: 1rem;
  height: 1rem;
  background: hsla(1, 75%, 55%, 1);
  border-radius: 50%;
  border-right: 1px solid hsla(1, 75%, 55%, 1);
}
.header > .loader:before {
  content: "";
  position: absolute;
  left: 3.4rem;
  top: -0.5rem;
  width: 0;
  height: 0;
  border-right: 1rem solid rgba(168, 40, 74);
  border-top: 0.5rem solid transparent;
  border-bottom: 0.5rem solid transparent;
}

@keyframes loading {
  50% {
    width: 20rem !important;
    border-left: 10px solid rgba(168, 40, 74);
    border-bottom: 10px solid rgba(168, 40, 74);
    border-right: 10px solid rgba(168, 40, 74);
    border-top: 8px solid rgba(168, 40, 74);
  }

  100% {
    width: 20rem !important;
    transform: rotate(360deg);
  }
}
.form {
  // margin: 0 0 0 2rem;
  padding: 0.5rem;
}

.input {
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 0.5rem 1rem;
  box-shadow: none;
  border-color: hsla(0, 5%, 0%, 1);
  border-width: 0 0 3px 0;
  transition: all 0.5s ease-in;
  outline: transparent;
}
.input + .input {
  margin: 10px 0 0;
}
.input:focus {
  border-bottom: 3px solid rgba(168, 40, 74);
}

.btn__log {
  position: absolute;
  right: 7.8rem;
  bottom: 3rem;
  width: 4rem;
  height: 4rem;
  border: none;
  background: hsla(255, 255%, 255%, 1);
  font-size: 0;
  border: none;
  transition: all 0.3s ease-in-out;
}
.btn__log:after {
  content: "";
  position: absolute;
  left: 1.4rem;
  top: 1rem;
  width: 0;
  height: 0;
  border-left: 1.6rem solid rgba(168, 40, 74);
  border-top: 0.8rem solid transparent;
  border-bottom: 0.8rem solid transparent;
  transition: border 0.3s ease-in-out 0s;
}
.btn__log:hover,
.btn__log:focus,
.btn__log:active {
  outline: none;
}
.btn__log:hover:after,
.btn__log:focus:after,
.btn__log:active:after {
  border-left-color: hsla(0, 5%, 0%, 1);
}
.resetbtn {
  margin: 1%;
  border: none;
  padding: 0.5em;
  width: 5em;
  background: hsla(0, 0%, 0%, 1);
  color: hsla(255, 255%, 255%, 1);
  font-size: 1.5em;
  border-radius: 2px;
  transition: all 1s ease-in-out;
  outline: none;
  box-shadow: 0 0 1px 1px hsla(0, 0%, 0%, 0.2);
}
.resetbtn:hover,
.resetbtn:focus {
  background: hsla(255, 255%, 255%, 1);
  color: hsla(0, 0%, 0%, 1);
  outline: 5px solid hsla(0, 0%, 0%, 1);
}

@media screen and (max-width: 768px) {
  .login {
    width: 95%;
  }
  .header {
    width: 100%;
  }
}
@media screen and (min-width: 700px) {
  // .login {
  //   width: 50vw;
  // }
  .login__submit {
    width: 50%;
  }
}
.login__submit:hover {
  color: linear-gradient(rgba(168, 40, 74), rgb(218, 120, 75));
  background-color: #1f2833;
  font-weight: 700;
  border: 2px solid #000;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
  border-color: linear-gradient(rgba(168, 40, 74), rgb(218, 120, 75));
  outline: none;
}

.button__icon {
  font-size: 24px;
  margin-left: auto;
  color: #fafafa;
}

.social-login {
  position: absolute;
  height: 140px;
  width: 160px;
  text-align: center;
  bottom: 0px;
  right: 0px;
  color: #fafafa;
}

.social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-login__icon {
  padding: 20px 10px;
  color: #fafafa;
  text-decoration: none;
  text-shadow: 0px 0px 8px #7875b5;
}

.social-login__icon:hover {
  transform: scale(1.5);
}

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
.new-login {
  position: absolute;
  left: 0;
  top: 0;
}
section {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #000;
  overflow: hidden;
}
section::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(#000, rgba(168, 40, 74), rgb(218, 120, 75));
  animation: animate 5s linear infinite;
}
@keyframes animate {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}
section span {
  position: relative;
  display: block;
  width: calc(6.25vw - 2px);
  height: calc(6.25vw - 2px);
  background: $border-color;
  z-index: 2;
  transition: 1.5s;
}
section span:hover {
  background-image: linear-gradient(
    to top,
    rgba(168, 40, 74),
    rgb(218, 120, 75)
  );
  transition: 0s;
}

section .signin {
  position: absolute;
  width: 400px;
  background: $border-color;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  border-radius: 4px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 9);
}
section .signin .content {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 40px;
}
section .signin .content h2 {
  font-size: 2em;
  color: rgba(168, 40, 74);
  text-transform: uppercase;
}
section .signin .content .form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
section .signin .content .form .inputBox {
  position: relative;
  width: 100%;
}
section .signin .content .form .inputBox input {
  position: relative;
  width: 100%;
  background: $content-bg;
  border: none;
  outline: none;
  padding: 25px 10px 7.5px;
  border-radius: 4px;
  color: #fff;
  font-weight: 500;
  font-size: 1em;
}
section .signin .content .form .inputBox i {
  position: absolute;
  left: 0;
  padding: 15px 10px;
  font-style: normal;
  color: #aaa;
  transition: 0.5s;
  pointer-events: none;
}
.signin .content .form .inputBox input:focus ~ i,
.signin .content .form .inputBox input:valid ~ i {
  transform: translateY(-7.5px);
  font-size: 0.8em;
  color: #fff;
}
.signin .content .form .links {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.signin .content .form .links a {
  color: #fff;
  text-decoration: none;
}
.signin .content .form .links a:nth-child(2) {
  color: rgba(168, 40, 74);
  font-weight: 600;
}
.signin .content .form .inputBox input[type="submit"] {
  padding: 10px;
  background-image: linear-gradient(rgba(168, 40, 74), rgb(218, 120, 75));
  color: #fff;
  font-weight: 600;
  font-size: 1.35em;
  border-radius: 15px;
  letter-spacing: 0.05em;
  cursor: pointer;
}
input[type="submit"]:active {
  opacity: 0.6;
}
@media (max-width: 900px) {
  section span {
    width: calc(10vw - 2px);
    height: calc(10vw - 2px);
  }
}
@media (max-width: 600px) {
  section span {
    width: calc(20vw - 2px);
    height: calc(20vw - 2px);
  }
}
.custom-checkbox {
  accent-color: #e91e63;
}
.apply-action {
  background-color: #e91e63;
  border: none;
}
.apply-action:hover {
  background-color: transparent;
  border: none;
}
.action {
  margin-top: 5px;
  display: flex;
  gap: 7px;
}

.transparent {
  background-color: transparent;
  border-color: transparent;
}
