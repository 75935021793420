.template-manager {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  
    h2 {
      margin-bottom: 15px;
    }
  
    .template-items {
      margin-bottom: 20px;
      .template-item {
        margin-bottom: 10px;
        label {
          display: flex;
          align-items: center;
          input {
            margin-right: 10px;
          }
          .template-name {
            font-weight: bold;
            margin-right: 10px;
          }
          .template-status {
            text-transform: capitalize;
            margin-right: 10px;
            &.approved { color: green; }
            &.unsubmitted { color: orange; }
            &.rejected { color: red; }
          }
          .template-reason {
            font-style: italic;
            color: #555;
          }
        }
      }
    }
  
    .pagination-controls {
      margin-bottom: 15px;
      button {
        margin-right: 10px;
        padding: 8px 16px;
        background: #007BFF;
        border: none;
        color: white;
        border-radius: 4px;
        cursor: pointer;
        &:disabled {
          background: #ccc;
          cursor: not-allowed;
        }
        &:hover:not(:disabled) {
          background: #0056b3;
        }
      }
    }
  
    .refresh-button {
      padding: 8px 16px;
      background: #007BFF;
      border: none;
      color: white;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background: #0056b3;
      }
    }
  
    .selected-template-info {
      margin-top: 15px;
      font-size: 14px;
      color: #333;
    }
  
    hr {
      margin: 30px 0;
    }
  
    .template-send {
      h2 { margin-bottom: 15px; }
      .message { margin-bottom: 15px; color: blue; }
      .csv-upload {
        margin-bottom: 20px;
        h3 { margin-bottom: 5px; }
        .dropzone {
          border: 2px dashed #ccc;
          border-radius: 4px;
          padding: 20px;
          text-align: center;
          color: #999;
          cursor: pointer;
          transition: border-color 0.3s, background-color 0.3s;
        }
        .dropzone.active {
          border-color: #007BFF;
          background-color: #f0f8ff;
        }
      }
      .send-button,
      .test-send-button {
        padding: 10px 20px;
        background: #007bff;
        border: none;
        color: white;
        border-radius: 4px;
        cursor: pointer;
        margin-right: 10px;
        &:hover {
          background: #0056b3;
        }
      }
      .single-send {
        margin-top: 20px;
        h3 { margin-bottom: 5px; }
        input[type="text"] {
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 4px;
          width: 60%;
          margin-right: 10px;
        }
      }
    }
  }
  