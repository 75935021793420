.fees-tabs {
    max-width: 600px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  
    .tabs {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;
      border-bottom: 2px solid #ccc;
  
      li {
        padding: 10px 20px;
        cursor: pointer;
        margin-right: 10px;
        border-bottom: 2px solid transparent;
        transition: all 0.3s ease;
  
        &:hover {
          background-color: #f7f7f7;
          color: #000;
        }
  
        &.active {
          border-color: #007bff;
          font-weight: bold;
          color: #007bff;
        }
      }
    }
  
    .tab-content {
      padding: 20px;
      background: #fff;
  
      .currency-group {
        margin-bottom: 20px;
  
        h3 {
          margin-bottom: 10px;
          font-size: 1.2em;
          color: #333;
        }
  
        .input-group {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
  
          label {
            width: 120px;
            font-weight: bold;
            color: #555;
          }
  
          input {
            flex: 1;
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 1em;
          }
        }
      }
    }
    button {
        padding: 5px 10px;
        background-color: #fff;
        color: #000;
        border-radius: 20px;
        font-weight: 700;
        cursor: pointer;
        &:disabled {
          background-color: #ccc;
          color: #000;
          cursor: not-allowed;
        }
        &:hover {
          background-color: #f7f7f74d;
          color: #fff;
        }
    }
  }
  