.template-send {
    h2 {
      margin-bottom: 15px;
    }
    .message {
      margin-bottom: 15px;
      color: blue;
    }
    .template-selection, .csv-upload {
      margin-bottom: 20px;
    }
    .template-item {
      margin-bottom: 10px;
      label {
        display: flex;
        align-items: center;
        input {
          margin-right: 10px;
        }
      }
    }
    .send-button {
      padding: 10px 20px;
      background: #007bff;
      border: none;
      color: white;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background: #0056b3;
      }
    }
  }
  