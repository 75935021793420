.card__waves {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
  margin-bottom: 6px;
  border-radius: 15px;
  background-color: #2a3038;

  button {
    background-image: linear-gradient(
      to right,
      rgba(168, 40, 74),
      rgb(218, 120, 75)
    );
    padding: 8px;
    color: white;
    border-radius: 15px;
    border: none;
    height: 50px;
    cursor: pointer;
  }
}
