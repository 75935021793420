.date-filter-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.date-filter-field {
  margin-right: 16px;
}

.apply-filter-button {
  background-color: #4caf50;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.apply-filter-button:hover {
  background-color: #45a049;
}

.apply-filter-button:active {
  background-color: #3e8e41;
}
