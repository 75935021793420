.card-wrapper-customers {
  width: 300px;
  border: 1px solid #d8dde5;
  border-radius: 10px;
  box-shadow: #d8dde5 0px 0px 2px;
  overflow: hidden;
  float: left;
  background-color: $input-bg;
  margin: 0 15px 15px;
}
.line {
  border-bottom: 1px solid black; /* add a border to the right side */
}

.space {
  padding-left: 1px;
}
.card-wrapper-customers__bg {
  height: 100px;
  background: #dfdfdf url("http://ringvemedia.com/server//bg.jpg") no-repeat;
  background-position: 100% 100%;
  position: relative;
}

.card-wrapper-customers__cover-bg {
  height: 100%;
  text-align: center;

  img {
    height: 100%;
  }
}

.card-wrapper-customers__profile-pic {
  position: absolute;
  width: 100px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 100px;
  height: 100px;
  left: 50%;
  margin-left: -50px;
  top: 30px;
  overflow: hidden;
  padding: 10px;

  img {
    width: 100%;
    height: 100%;
  }
}

.text-center {
  //   text-align: center;
}

.customer__flex {
  display: flex;
  flex-direction: column;
}

.card-wrapper-customers__details {
  //   padding-top: 65px;
  padding-bottom: 20px;
}

.card-wrapper-customers__user-name a {
  color: #1085e0;
  text-decoration: none;
  font-size: 20px;
}

.card-wrapper-customers__scanCodeID {
  color: #fafafa;
  text-decoration: none;
  font-size: 18px;
}
.card-wrapper-customers__user-detail {
  font-size: 16px;
  color: #707070;
}
.email {
  font-size: 13px;
}
.card-wrapper-customers__user-contact-info {
  padding-top: 20px;

  .card-wrapper-customers__user-contact-info-label {
    font-size: 16px;
    color: #707070;
  }

  .card-wrapper-customers__user-contact-info-value,
  .card-wrapper-customers__user-contact-info-value a {
    font-size: 18px;
    color: #000;
    text-decoration: none;
  }
}

//new Design

.card--customer {
  width: 350px;
  background-color: $input-bg;
  border-radius: 15px;
  padding: 10px;
  margin: 10px;
  margin-right: 5vw;
  hr {
    background-color: $white;
    margin: 6px 0;
  }
  .first-section__customer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .card-customers__user-details {
      p {
        font-size: 24px;
        color: $white;
      }
      span {
        font-size: 14px;
        color: #76838f;
      }
    }
    .card-customers__date {
      background-color: $sidebar-bg;
      color: $white;
      height: fit-content;
      font-size: 16px;
      text-align: center;
      padding: 8px;
      border-radius: 20px;
    }
  }
  .second-section__customer {
    color: $white;
    font-size: 16px;
  }
  .third-section__customer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .buttons__customer {
      background-color: $sidebar-bg;
      color: $white;
      border: none;
      border-radius: 15px;
      padding: 5px 16px;
    }
    .download {
      background-image: linear-gradient(
        to right,
        rgba(168, 40, 74),
        rgb(218, 120, 75)
      );
    }
    .wider {
      width: 100%;
    }
  }
}

@media screen and (max-width: 700px) {
  .marginButton {
    font-size: small;
    margin-left: 1rem;
  }
  .card--customer {
    width: 235px;
    background-color: $input-bg;
    border-radius: 15px;
    padding: 10px;
    margin: 10px;
    hr {
      background-color: $white;
      margin: 6px 0;
    }
    .first-section__customer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .card-customers__user-details {
        max-width: 37%;
        p {
          font-size: 16px;
          color: $white;
        }
        span {
          font-size: 10px;
          color: #76838f;
        }
      }
      .card-customers__date {
        background-color: $sidebar-bg;
        color: $white;
        height: fit-content;
        font-size: 12px;
        text-align: center;
        padding: 8px;
        border-radius: 20px;
      }
    }
    .second-section__customer {
      color: $white;
      font-size: 12px;
    }
    .third-section__customer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .buttons__customer {
        background-color: $sidebar-bg;
        color: $white;
        border: none;
        border-radius: 15px;
        padding: 3px 6px;
      }
      .download {
        background-image: linear-gradient(
          to right,
          rgba(168, 40, 74),
          rgb(218, 120, 75)
        );
      }
    }
  }
}
