.accordion {
  font-family: Inter, sans-serif;
  padding: 1rem;
  background-color: #2a3038;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-bottom: 1rem;
  width: auto;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title h4 {
  font-weight: 600;
  margin: 0;
  color: #fafafa;
}

.accordion .p {
  display: none;
  margin-top: 6px;
  margin-bottom: 0;
  font-family: Inter, sans-serif;
  color: #fafafa;
  background-color: #191c24;
  padding: 8px;
  border-radius: 15px;
  cursor: default;
}

.accordion.open {
  border-bottom: 2px solid;
  border-image: linear-gradient(to right, #b1b2b341, #b1b2b341);
  border-image-slice: 1;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 0.5rem solid #fafafa;
  border-bottom: 0;
  transition: 0.3s;
  border-radius: 35%;
}

.accordion.open .p {
  display: block;
}

.accordion.open .title .arrow-down {
  transform: rotate(180deg);
  border-top-color: #b1b2b3;
}

@media screen and (max-width: 700px) {
  .wave-fees__button {
    font-size: small;
  }
}

.sticky {
  position: sticky;
  left: 0;
  // background-color: #343a40;
}

.first-column {
  left: 0;
  z-index: 1; /* Ensure the first column stays above the other columns */
}

.last-column {
  right: 0;
  z-index: 1; /* Ensure the last column stays above the other columns */
}
