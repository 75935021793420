@import url("https://fonts.googleapis.com/css?family=Lato");
.date-range {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.date-input {
  display: flex;
  flex-direction: column;
}
.date-input .label {
  padding-left: 10px;
  font-size: 12px;
  color: #fff;
  display: flex;
}
.react-datepicker__input-container input {
  background-color: #0f1015;
  padding: 8px;
  border: none;
  border-radius: 15px;
  color: $white;
}
.react-datepicker__input-container input::placeholder {
  padding: 5px;
  color: $white;
}

.arrow {
  color: $white;
  margin-top: 10px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* Styles for the arrow */
.dropdown-select::after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  pointer-events: none;
}
@media (max-width: 768px) {
  .download__invoices {
    font-size: 12px;
    margin-left: 5px;
    padding: 10px 0px !important;
  }
  .download__refund {
    font-size: 12px;
    margin-left: 5px;
    margin-right: 14px;
    padding: 10px 0px !important;
  }
  .apply__filter {
    font-size: 14px;
  }
  // .date-input {
  //   width: 100px;
  // }
  .react-datepicker__input-container input {
    width: 100px;
  }
}

$background: #e74c3c;
$select-color: #fff;
$select-background: #0f1015;
$select-width: 220px;
$select-height: 40px;

.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}
.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  color: $select-color;
  width: $select-width;
  height: $select-height;
}
.select-styled {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: none;
  border-radius: 15px;
  background-color: $select-background;
  padding: 8px 15px;
  @include transition(all 0.2s ease-in);
  &:after {
    content: "";
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-color: none;
    position: absolute;
    top: 16px;
    right: 10px;
  }
  &:hover {
    background-color: darken($select-background, 2);
  }
  &:active,
  &.active {
    background-color: darken($select-background, 5);
    &:after {
      top: 9px;
      border-color: none;
    }
  }
}

.select-options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: darken($select-background, 5);
  li {
    margin: 0;
    padding: 12px 0;
    text-indent: 15px;
    border-top: 1px solid darken($select-background, 10);
    @include transition(all 0.15s ease-in);
    &:hover,
    &.is-selected {
      color: $select-background;
      background: $select-color;
    }
    &[rel="hide"] {
      display: none;
    }
  }
}

.filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.download__invoices {
  border-radius: 15px;
  background-color: #0f1015;
  color: $white;
  margin-left: 7px;
  padding: 8px;
  margin-top: 20px;
  border: none;
  box-shadow: inset 0 0 0.3em 0 rgb(253, 0, 118), 0 0 0.3em 0 rgb(253, 0, 118);
  :hover {
    box-shadow: inset 0 0 0.3em 0 rgb(253, 0, 118), 0 0 0.3em 0 rgb(253, 0, 118);
  }
  ::after {
    box-shadow: inset 0 0 0.3em 0 rgb(253, 0, 118), 0 0 0.3em 0 rgb(253, 0, 118);
  }
  span {
    color: rgb(253, 0, 118);
  }
}

.download__refund {
  border-radius: 15px;
  background-color: #0f1015;
  color: $white;
  margin-left: 7px;
  padding: 8px;
  margin-top: 20px;
  border: none;
  margin-right: 7px;
  box-shadow: inset 0 0 0.3em 0 rgb(253, 0, 118), 0 0 0.3em 0 rgb(253, 0, 118);
  :hover {
    box-shadow: none;
  }
  ::after {
    box-shadow: none;
    color: #bb6944;
  }
  span {
    color: rgb(253, 0, 118);
  }
}

.apply__filter {
  border-radius: 15px;
  background-image: linear-gradient(
    to top,
    rgba(168, 40, 74),
    rgb(218, 120, 75)
  );
  color: $white;
  padding: 8px 14px;
  margin-top: 20px;
  border: none;
}
