.dialog-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 9999;
  transition: opacity 0.5s ease-in-out;
  border: 2px solid #e67e22;
}

.hide {
  opacity: 0;
  pointer-events: none;
}

.message {
  font-size: 18px;
  text-align: center;
  margin: 0;
  padding: 0;
}
