.main-whatsapp-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: black;
    min-width: fit-content;
}

.tabs {
    display: flex;
    list-style: none;
    padding: 0;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;

    li {
        padding: 10px 20px;
        margin-right: 5px;
        cursor: pointer;
        border: 1px solid transparent;
        border-radius: 5px 5px 0 0;
        color: white;

        &.active {
            border-color: #ccc #ccc white;
            background: white;
            border-bottom: 1px solid white;
            color: black;
        }
    }
}

.tab-content {
    background: white;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 0 5px 5px 5px;
}

/* Navigation buttons for next/previous tabs */
.tab-navigation {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .nav-button {
        padding: 10px 20px;
        background: #007BFF;
        border: none;
        color: white;
        border-radius: 4px;
        cursor: pointer;

        &:disabled {
            background: #ccc;
            cursor: not-allowed;
        }

        &:hover:not(:disabled) {
            background: #0056b3;
        }
    }
}