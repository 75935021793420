// @import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,700");
// @media screen and (max-width: 700px) {
//   .container__drop {
//     width: 100% !important;
//   }
// }
// .container__drop {
//   height: 50vh;
//   width: 30vw;
//   margin: 0 0;
//   // padding: 20px;
//   // box-shadow: 0 0.5rem 2rem 1rem rgba(0, 0, 0, 0.1);
//   border-radius: 15px;
//   overflow: hidden;

//   label,
//   input {
//     display: block;
//     height: 10vh;
//     width: 100%;
//     line-height: 5rem;
//     box-sizing: border-box;
//   }

//   label {
//     font-size: 15px;
//     font-weight: 900;
//   }

//   input {
//     font-size: 16px;
//   }

//   .wrapper__drop {
//     position: relative;

//     input {
//       padding: 0rem 2.5rem;
//       border: 0;
//       // box-shadow: 0 5px 20px 10px rgba(0, 0, 0, .1);
//       // border-radius: 50px;
//       text-transform: capitalize;
//       border: 1px solid #eee;

//       &:focus {
//         outline: none;
//         border: 2px solid #ccc;
//       }
//     }

//     .list__drop {
//       position: absolute;
//       top: 10vh;
//       left: 0;
//       height: 35vh;
//       width: 100%;
//       overflow-x: hidden;
//       overflow-y: scroll;
//       // border-radius: 0 0 50px 50px;
//       // box-shadow: 0 5px 20px 10px rgba(0, 0, 0, .1);
//       border: 1px solid #eee;

//       ul {
//         list-style: none;
//         height: 50%;
//         margin: 0;
//         padding: 0;

//         li {
//           height: 8vh;
//           margin: 0;
//           padding: 0;
//           display: flex;
//           align-items: center;

//           &:hover {
//             background-color: #eee;
//             cursor: pointer;
//           }

//           img {
//             width: 2.4rem;
//             height: 1.4rem;
//             margin: 0 2rem;
//           }

//           p {
//             font-size: 16px;
//             text-transform: capitalize;
//           }
//         }
//       }
//     }
//   }
// }

@media screen and (max-width: 700px) {
  .wrapper__drop {
    input {
      width: 90% !important;
    }
  }
}

.under__search {
  margin-top: 5%;
}

.wrapper__drop {
  input {
    background-color: $input-bg;
    border-radius: 15px;
    padding: 10px;
    color: $white;
    width: 400px;
  }

  .phone__search_event {
    width: 50vw;
  }

  .list__drop {
    ul {
      list-style: none;
      margin-top: 10px;
      max-width: 400px;
      max-height: 200px;
      overflow-y: scroll;

      li {
        display: flex;
        align-items: center;
        height: 70px;

        img {
          border-radius: 50%;
          width: 50px;
          margin-right: 2rem;
          height: 50px;
          float: left;
        }

        p {
          padding-top: 10px;
        }
      }
    }
  }
}

.top-picks-step {
  .email-form-container {
    max-width: 500px;
    margin: 2rem 0;
    padding: 1.5rem;
    background-color: #fafafa;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    .email-form {
      display: flex;
      flex-direction: column;

      .form-group {
        margin-bottom: 1.2rem;

        label {
          display: block;
          margin-bottom: 0.5rem;
          font-weight: bold;
          color: #333;
        }

        input[type="text"],
        input[type="date"] {
          width: 100%;
          padding: 0.8rem;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 1rem;
          transition: border-color 0.3s;

          &:focus {
            border-color: #007BFF;
            outline: none;
          }
        }

        .note {
          display: block;
          font-size: 0.8rem;
          color: #888;
          margin-top: 0.3rem;
        }
      }

      .submit-btn {
        padding: 0.8rem 1.2rem;
        font-size: 1rem;
        color: #fff;
        background-color: #007BFF;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }


}

.send-top-picks-main {

  /* Step Indicator Styling */
  .step-indicator {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .step {
      flex: 1;
      text-align: center;
      padding: 10px 0;
      font-size: 16px;
      font-weight: 500;
      color: #777;
      border-bottom: 2px solid #ccc;
      position: relative;
      transition: all 0.3s ease;

      &.active {
        color: #007bff;
        border-color: #007bff;
        font-weight: 700;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }

      &:not(:last-child)::after {
        content: "";
        position: absolute;
        top: 50%;
        right: -5px;
        transform: translateY(-50%);
        width: 10px;
        height: 2px;
        background-color: #ccc;
      }
    }
  }

  /* Navigation Buttons Container */
  .nav-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  /* Common Button Styling */
  .nav-btn,
  .submit-btn {
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  /* Previous & Next Button Design */
  .nav-btn {
    background-color: #007bff;
    color: #fff;

    &:hover {
      background-color: #0056b3;
    }

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }

  /* Submit Button Design */
  .submit-btn {
    background-color: #28a745;
    color: #fff;

    &:hover {
      background-color: #218838;
    }
  }
}

.form-group {
  .upload-container {
    max-width: 600px;
    margin: 40px auto;
    font-family: 'Arial', sans-serif;
  }
  
  .upload-area {
    border: 2px dashed #cccccc;
    border-radius: 8px;
    padding: 40px;
    text-align: center;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    cursor: pointer;
    background-color: #fafafa;
  }
  
  .upload-area:hover {
    background-color: #f0f0f0;
    border-color: #aaaaaa;
  }
  
  .upload-area p {
    margin: 0;
    font-size: 16px;
    color: #555;
  }
  .check-icon {
    font-size: 20px;
    margin-right: 8px;
  }
  .upload-success {
    margin-top: 20px;
    padding: 10px;
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  .result {
    margin-top: 30px;
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .result h3 {
    margin-top: 0;
    color: #333;
  }
  
  .result ul {
    list-style: none;
    padding: 0;
  }
  
  .result li {
    background: #ffffff;
    margin: 8px 0;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
    font-size: 14px;
  }
  
}
/* Target buttons, SVGs, paths, picker labels, and items */
.ql-toolbar button,
.ql-toolbar button svg,
.ql-toolbar button svg path,
.ql-toolbar .ql-picker-label,
.ql-toolbar .ql-picker-item {
  // fill: #fff !important;
  // stroke: #fff !important;
  color: #fff !important;
}

/* Override active, hover, and focus states */
.ql-toolbar button:hover,
.ql-toolbar button:focus,
.ql-toolbar button.ql-active {
  background-color: #444 !important; /* Optional: change active state background */
  color: #fff !important;
}

/* Optionally, adjust the toolbar background for better contrast */
.ql-toolbar {
  background-color: #333 !important;
}
.ql-stroke{
  stroke: #fff !important;
}
.ql-fill{
  fill: #fff !important;
} 
.ql-transparent {
  opacity: 1 !important;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background-color: #333;
}