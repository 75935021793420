.custom-table-content {
  width: 100%;
  overflow-x: auto;
}

.custom-table-responsive {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  color: white;
  thead {
    border-bottom: 1px solid rgba(255, 255, 255, 0.164);
  }
  tr {
    line-height: 2.7rem;
  }
  .normal-row {
    cursor: pointer;
  }
  .active-row {
    cursor: pointer;
  }
  th,
  td {
    padding: 10px;
    border: none;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  .badge {
    border: 3px solid yellow;
    border-radius: 15px;
  }
  .empty-badge {
    display: flex;
    justify-content: center;
  }
  .num-tickets {
    font-size: 14px;
    background-color: #f57451;
    color: white;
    border-radius: 50%;
    padding: 1px 5px;
  }
  .action-button {
    line-height: normal;
    border-radius: 20px;
    background-color: transparent;
    border: 2px solid #f57451;
    padding: 7px 15px;
    color: white;
    font-size: 14px;
    margin-right: 5px;
  }
}

.full-paginate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 12px;
  }
  .rows-count {
    display: flex;
    gap: 10px;
  }
  .selections {
    display: flex;
    gap: 10px;
    justify-content: space-around;
    .active {
      box-shadow: inset 0 0 0 2px #f57451;
    }

    span {
      cursor: pointer;
      background: #191c24;
      border-radius: 50%;
      height: 26px;
      width: 26px;
      line-height: 26px;
      display: inline-block;
      text-align: center;
      margin-right: 6px;
    }
  }
}
.tickets-fees {
  input[type="number"] {
    line-height: 2.5;
    padding-top: 8px;
  }
}
.remain-note {
  line-height: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .values {
    background: linear-gradient(to right, #a8284a, #da784b);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.fees-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    label {
      text-align: left;
      line-height: normal;
    }

    input[type="number"] {
      width: 150px;
      border-radius: 20px;
      border: none;
      background-color: #0f1015;
      color: white;
      padding-left: 10px;
    }
  }
}
.sub-table {
  background-color: #0f1015;
  font-size: 12px;
  border-radius: 20px;
  thead {
    line-height: normal !important;
  }
  tr {
    line-height: normal !important;
  }
  td {
    width: auto !important;
  }
}
@media screen and (max-width: 600px) {
  .custom-table-responsive {
    th,
    td {
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: unset;
    }

    th {
      text-align: center;
    }
  }

  .full-paginate {
    .selections {
      gap: 5px !important;
    }
  }
}
