.search_list {
  width: 50%;
  margin: 50px auto;
  padding: 20px;
}
.search_list input::placeholder {
  color: $white;
  opacity: 1;
}

.search_list input {
  width: 100%;
  color: $white;
  background-color: #1c212e;
}
.search_list ul {
  list-style-type: none;
  margin-top: 1vh;
  li {
    // overflow: hidden;
    margin: 0 0 20px;
    border-bottom: 1px solid #ccc;
    padding: 0 0 20px;
    &:last-child {
      margin: 0;
    }
  }
}

.list {
  // overflow: hidden;
  margin: 0 0 20px;
  border-bottom: 1px solid #ccc;
  padding: 0 0 20px;
  cursor: pointer;
  &:last-child {
    margin: 0;
  }
}
.search_list img {
  float: left;
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 10px 0 0;
}
.search_list span {
  display: block;
  color: $white;
  width: 100%;
  font-weight: bolder;
  &.phone {
    font-weight: normal;
    font-style: italic;
    color: #999;
  }
}
.space {
  padding-top: 2vh;
}
@media screen and (max-width: 700px) {
  .search_list {
    width: 100%;
    padding: 0;
  }
}
