.search-list-wrapper,
.event-details-wrapper {
  opacity: 0;
  position: absolute;
  width: 77vw;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0s linear 0.5s;
}

@media screen and (max-width: 700px) {
  .search-list-wrapper,
  .event-details-wrapper {
    width: 83vw;
  }
}

.show {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease, visibility 0s linear 0s;
}

.hide {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0s linear 0s;
}

.cf:before,
.cf:after {
  content: "";
  display: table;
}
.cf:after {
  clear: both;
}

/*--------------------------------------------------------------
2.0 - SEARCH FORM
--------------------------------------------------------------*/
.searchform {
  background: $input-bg;
  background: rgba(42, 48, 56, 1);
  border: 1px solid $input-bg;
  // left: 50%;
  // padding: 2px 5px;
  // position: absolute;
  // margin: -22px 0 0 -170px;
  // top: 50%;
  margin-left: 1.3vw;
  margin-bottom: 2vh;
  width: 268px;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 0 4px 9px rgba(0, 0, 0, 0.37);
  -webkit-box-shadow: 0 4px 9px rgba(0, 0, 0, 0.37);
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}

.searchform input,
.searchform button {
  float: left;
}
.searchform input {
  background: $input-bg;
  border: none;
  font: 12px/12px "HelveticaNeue", Helvetica, Arial, sans-serif;
  margin-right: 5px;
  padding: 10px;
  color: white;
  width: 180px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4) inset, 1px 1px 1px rgba(42, 48, 56, 1);
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.4) inset,
    1px 1px 1px rgba(42, 48, 56, 1);
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.4) inset,
    1px 1px 1px rgba(42, 48, 56, 1);
  border-radius: 9px;
  -moz-border-radius: 9px;
  -webkit-border-radius: 9px;
}
.searchform input:focus {
  outline: none;
  box-shadow: 0 0 4px rgba(168, 40, 74) inset;
  -moz-box-shadow: 0 0 4px rgba(168, 40, 74) inset;
  -webkit-box-shadow: 0 0 4px rgba(168, 40, 74) inset;
}
.searchform input::-webkit-input-placeholder {
  font-style: italic;
  line-height: 15px;
}

.searchform input:-moz-placeholder {
  font-style: italic;
  line-height: 15px;
}

.load__button {
  background: rgb(100, 100, 100) !important;
  background: -moz--gradient(
    top,
    rgb(100, 100, 100) 0%,
    rgb(100, 100, 100) 100%
  ) !important;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgb(100, 100, 100)),
    color-stop(100%, rgb(100, 100, 100))
  ) !important;
  background: -webkit-linear-gradient(
    top,
    rgb(100, 100, 100) 0%,
    rgb(100, 100, 100) 100%
  ) !important;
  background: -o-linear-gradient(
    top,
    rgb(100, 100, 100) 0%,
    rgb(100, 100, 100) 100%
  ) !important;
  background: -ms-linear-gradient(
    top,
    rgb(100, 100, 100) 0%,
    rgb(100, 100, 100) 100%
  ) !important;
  background: linear-gradient(
    to bottom,
    rgb(100, 100, 100) 0%,
    rgb(100, 100, 100) 100%
  ) !important;
  box-shadow: 0 0 2px rgb(100, 100, 100) inset !important;
  -moz-box-shadow: 0 0 2px rgb(100, 100, 100) inset !important;
  -webkit-box-shadow: 0 0 2px rgb(100, 100, 100) inset !important;
}
.searchform button {
  background: rgba(168, 40, 74);
  background: -moz-linear-gradient(
    top,
    rgba(168, 40, 74) 0%,
    rgb(218, 120, 75) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(168, 40, 74)),
    color-stop(100%, rgb(218, 120, 75))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(168, 40, 74) 0%,
    rgb(218, 120, 75) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(168, 40, 74) 0%,
    rgb(218, 120, 75) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(168, 40, 74) 0%,
    rgb(218, 120, 75) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(168, 40, 74) 0%,
    rgb(218, 120, 75) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#34adec', endColorstr='#2691dc',GradientType=0 );
  border: none;
  color: #fff;
  cursor: pointer;
  font: 13px/13px "HelveticaNeue", Helvetica, Arial, sans-serif;
  padding: 10px;
  width: 80px;
  box-shadow: 0 0 2px rgba(168, 40, 74) inset;
  -moz-box-shadow: 0 0 2px rgba(168, 40, 74) inset;
  -webkit-box-shadow: 0 0 2px rgba(168, 40, 74) inset;
  border-radius: 9px;
  -moz-border-radius: 9px;
  -webkit-border-radius: 9px;
}
.searchform button:hover {
  opacity: 0.9;
}
.customers-list {
  overflow-y: scroll;
  max-height: 400px;
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
}
@media screen and (max-width: 700px) {
  .customers-list {
    max-height: 550px;
  }
}
