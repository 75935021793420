.template-creation {
    h2 {
      margin-bottom: 15px;
    }
    .message {
      margin-bottom: 15px;
      color: green;
    }
    form {
      .form-group {
        margin-bottom: 15px;
        label {
          display: block;
          margin-bottom: 5px;
        }
        input[type="text"],
        input[type="url"] {
          width: 100%;
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
        input[type="checkbox"] {
          margin-left: 10px;
        }
      }
      .submit-button {
        padding: 10px 20px;
        background: #007bff;
        border: none;
        color: white;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background: #007bff;
        }
      }
    }
  }
  