.logs-download {
    h2 {
      margin-bottom: 15px;
    }
    .message {
      margin-bottom: 15px;
      color: purple;
    }
    form {
      .form-group {
        margin-bottom: 15px;
        label {
          display: block;
          margin-bottom: 5px;
        }
        input[type="date"] {
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      }
      .fetch-button {
        padding: 10px 20px;
        background: #17a2b8;
        border: none;
        color: white;
        border-radius: 4px;
        cursor: pointer;
        margin-right: 10px;
        &:hover {
          background: #138496;
        }
      }
    }
    .download-button {
      padding: 10px 20px;
      background: #ffc107;
      border: none;
      color: #333;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background: #e0a800;
      }
    }
  }
  