$primary-color: #75253e;
$animation-duration: 0.4s;

.all-fees__inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.col-sm__fees {
  position: relative;
  width: 97%;
}
.fees__form {
  max-width: 450px;

  @mixin label-inactive() {
    font-size: 0.9rem;
    transform: translateY(0);
  }

  @mixin label-active() {
    font-size: 0.75rem;
    transform: translateY(-14px);
  }

  .form-field {
    display: block;
  }
  .form-field__label {
    @include label-active();
    display: block;
    font-weight: normal;
    left: 0;
    color: $white;
    margin: 0;
    padding: 18px 12px 0;
    position: absolute;
    top: 0;
    transition: all $animation-duration;
    width: 100%;
  }
  .form-field__control {
    background: $sidebar-bg;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    width: 97%;
  }
  .form-field__bar {
    border-bottom: 2px solid $primary-color;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    transform: scaleX(0);
    transition: all $animation-duration;
    width: 2%;
  }
  .form-field__input,
  .form-field__textarea {
    appearance: none;
    background: transparent;
    border: 0;
    border-bottom: 1px solid $sidebar-bg;
    color: $white;
    display: block;
    font-size: 1.2rem;
    margin-top: 24px;
    outline: 0;
    padding: 0 12px 10px 12px;
    width: 100%;

    // IE 10-11
    &:-ms-input-placeholder {
      ~ .form-field__label {
        @include label-inactive();
      }
    }
    // All other browsers except Edge
    &:placeholder-shown {
      ~ .form-field__label {
        @include label-inactive();
      }
    }
    &:focus {
      ~ .form-field__label {
        color: $primary-color;
        @include label-active();
      }
      ~ .form-field__bar {
        border-bottom: 2px solid $primary-color;
        transform: scaleX(150);
      }
    }
  }
  .form-field__textarea {
    height: 150px;
  }
}

@media screen and (max-width: 700px) {
  .fees__form {
    @mixin label-inactive-m() {
      font-size: 0.9rem;
      transform: translateY(0);
    }
    .form-field__input {
      padding: 0 12px 0px 12px;
    }
    .form-field__bar {
      width: 1%;
    }
  }
}
