/* Tables */

.table {
  margin-bottom: 0;
  color: white;
  thead {
    color: $text-muted;
    th {
      border-top: 0;
      border-bottom-width: 1px;
      font-family: $type1;
      font-weight: $font-weight-medium;
      i {
        margin-left: 0.325rem;
      }
    }
  }
  th,
  td {
    white-space: nowrap;
    vertical-align: middle;
    font-size: $default-font-size;
    line-height: 1;
    img {
      width: 30px;
      height: 30px;
      border-radius: 100%;
    }
    .badge {
      margin-bottom: 0;
    }
  }
  &.table-borderless {
    border: none;
    tr,
    td,
    th {
      border: none;
    }
  }
  tbody {
    tr:hover {
      background-color: #c02256;
    }
    tr {
      cursor: pointer;
    }
  }
}

.drop-area {
  border: 2px dashed gray;
  padding: 50px;
  text-align: center;
}

.drop-area.dragging {
  background-color: #f0f0f0;
}

.drop-area p {
  font-size: 16px;
  color: gray;
}

.file-input {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.file-input input[type="file"] {
  display: none;
}

.file-input-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: gray;
  padding: 10px 20px;
  border: 1px solid gray;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.file-input-label:hover {
  background-color: #f0f0f0;
}
.search-for-number {
  margin-top: 5%;
  // margin-left: 40%;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .search-for-number {
    // margin-left: 25%;
  }
}

.file-input-label.icon {
  background-color: #4caf50;
  color: white;
  font-weight: bold;
  padding: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.table-dark {
  background-color: transparent !important;
}
